.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.wrap-input100 {
  width: 100%;
  /* position: relative; */
  /* border-bottom: 2px solid #adadad;
  margin-bottom: 37px; */
}

.password-icon {
  position: relative;
  top: -30px;
  right: -93%;
  cursor: pointer;
}

.fa {
  color: #6c757d;
}

.login-button {
  background-color: #ced4da !important;
  border: none !important;
}

.loading-spinner {
  margin-left: 160px !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.sub-title {
  font-size: 14px;
  font-weight: 300;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
