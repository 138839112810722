* {
  font-family: "Ubuntu", sans-serif;
}

h4 {
  font-weight: 500 !important;
}

a {
  text-decoration: none !important;
}

body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-alternative {
  color: #5c5c5c;
}
